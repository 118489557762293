export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'รายการรับชำระ',
    route: 'inquiry-receive',
    icon: 'HomeIcon',
    resource: 'InquiryReceive',
    action: 'read',
  },
  {
    title: 'Set Up เงื่อนไขค่าเช่ารถ',
    icon: 'FileIcon',
    resource: 'SetupConditionRent',
    action: 'read',
    children: [
      {
        title: 'ข้อมูลหลักรถยนต์',
        route: 'car-master-maintainance',
        icon: 'FileIcon',
        resource: 'CarMasterMaintainance',
        action: 'read',
      },
      {
        title: 'ปรับปรุงสถานะรถยนต์',
        route: 'car-master-status',
        icon: 'FileIcon',
        resource: 'CarMasterstatus',
        action: 'read',
      },
      {
        title: 'ข้อมูลเงื่อนไขค่าเช่า',
        route: 'yourroutename',
        resource: 'ConditionRent',
        action: 'read',
        disabled: true,
      },
      {
        title: 'เรียกดูประวัติ',
        route: 'yourroutename',
        resource: 'RentHistory',
        action: 'read',
        disabled: true,
      },
      {
        title: 'ปรับปรุงสถานะรถ',
        route: 'yourroutename',
        resource: 'AdjustStatusCar',
        action: 'read',
        disabled: true,
      },
    ],
  },
  {
    header: 'จัดการกระบวนการ',
    resource: 'ManageProcess',
    action: 'read',
  },
  {
    title: 'กระบวนการสัญญา',
    icon: 'FileIcon',
    resource: 'ContractProcess',
    action: 'read',
    children: [
      {
        title: 'สร้างรายการสัญญา',
        route: 'contract-maker',
        icon: 'HomeIcon',
        resource: 'ContractMaker',
        action: 'read',
      },
      {
        title: 'อนุมัติรายการสัญญา',
        route: 'contract-approve',
        icon: 'HomeIcon',
        resource: 'ContractApprove',
        action: 'read',
      },
      {
        title: 'เรียกดูรายการสัญญา',
        route: 'contract-inquiry',
        icon: 'HomeIcon',
        resource: 'ContractInquiry',
        action: 'read',
      },
      {
        title: 'พิมพ์สัญญา',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
    ],
  },
  {
    title: 'กระบวนการรับชำระ',
    icon: 'FileIcon',
    resource: 'PaymentProcess',
    action: 'read',
    children: [
      {
        title: 'ประมวลผลรับชำระรายวัน',
        resource: 'PaymentProcessDaily',
        action: 'read',
        disabled: true,
      },
      {
        title: 'สถานะรับชำระและแก้ไขข้อมูล',
        route: 'digital-repayment',
        icon: 'HomeIcon',
        resource: 'DigitalRepayment',
        action: 'read',
      },
      {
        title: 'เปรียบเทียบยอดรับชำระสิ้นวัน',
        route: 'PaymentChecker',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'รายงานการรับชำระรายวัน',
        route: 'daily-receive-report',
        icon: 'FileIcon',
        resource: 'DailyReceiveReport',
        action: 'read',
      },
      {
        title: 'เรียกดูประวัติการรับชำระ',
        route: 'repayment-inquiry',
        icon: 'FileIcon',
        resource: 'RepaymentInquiry',
        action: 'read',
      },
    ],
  },
  {
    title: 'กระบวนการ Drop ค่าเช่า',
    icon: 'FileIcon',
    resource: 'RentalDropProcess',
    action: 'read',
    children: [
      {
        title: 'สร้างรายการ Drop ค่าเช่า',
        route: 'drop-rental',
        icon: 'FileIcon',
        resource: 'DropRental',
        action: 'read',
      },
      {
        title: 'อนุมัติการ Drop ค่าเช่า',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'จอดรถ',
        route: 'car-parking',
        icon: 'FileIcon',
        resource: 'CarParking',
        action: 'read',
      },
    ],
  },
  {
    title: 'กระบวนการติดตามหนี้',
    icon: 'FileIcon',
    resource: 'DeptProcess',
    action: 'read',
    children: [
      {
        title: 'รายงานอายุหนี้',
        route: 'aging-report',
        icon: 'FileIcon',
        resource: 'AgingReport',
        action: 'read',
      },
      {
        title: 'รายการอายุหนี้ (บริหาร)',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'แจ้งยึดรถ',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'บันทึกงานยึดรถ',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
    ],
  },
  {
    title: 'กระบวนการประกันภัย',
    icon: 'FileIcon',
    resource: 'InsuranceProcess',
    action: 'read',
    children: [
      {
        title: 'ปรับปรุงข้อมูลประกันใหม่ และต่อประกัน',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'รายงานแจ้งเตือนการต่อประกัน',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'เรียกดูประวัติการต่อประกัน',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
    ],
  },
  {
    title: 'กระบวนการทะเบียน',
    icon: 'FileIcon',
    resource: 'RegistrationProcess',
    action: 'read',
    children: [
      {
        title: 'ปรับปรุงข้อมูลทะเบียนทำใหม่ และต่อทะเบียน',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'รายงานแจ้งเตือนการต่อทะเบียน',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'เรียกดูประวัติการต่อทะเบียน',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
    ],
  },
  {
    title: 'กระบวนการอื่นๆ',
    icon: 'FileIcon',
    resource: 'MoreProcess',
    action: 'read',
    children: [
      {
        title: 'รายงานค่าบริการ',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
      {
        title: 'รายงานค่าดูแลสำหรับเซลล์',
        route: 'yourroutename',
        resource: 'yourroutename',
        action: 'read',
        disabled: true,
      },
    ],
  },
  {
    header: 'ผู้ใช้งานระบบ และกำหนดสิทธิ',
    resource: 'MemberProcess',
    action: 'read',
  },
  {
    title: 'ผู้ใช้งานระบบ',
    route: 'member',
    icon: 'UsersIcon',
    resource: 'Member',
    action: 'read',
    // disabled: true,
  },
  {
    title: 'ข้อมูลพื้นฐาน',
    icon: 'FolderIcon',
    resource: 'Member',
    action: 'read',
    children: [
      {
        title: 'แผนก',
        route: 'department',
        resource: 'Department',
        action: 'read',
      },
      {
        title: 'ตำแหน่ง',
        route: 'position',
        resource: 'Position',
        action: 'read',
      },
      {
        title: 'ประเภทผู้ใช้',
        route: 'member-type',
        resource: 'MemberType',
        action: 'read',
      },
    ],
  },
  {
    title: 'กำหนดสิทธิผู้ใช้งาน',
    route: 'permission',
    icon: 'UnlockIcon',
    resource: 'Permission',
    action: 'read',
    // disabled: true,
  },
  {
    title: 'ระบบ',
    route: 'system',
    icon: 'CpuIcon',
    resource: 'System',
    action: 'read',
    // disabled: true,
  },
]
